

export default {
    namespaced: true,
    state: {
        token: JSON.parse(localStorage.getItem('userInfo'))?.token || '',
        user: '',
        type: '',
        cardKey: '',
        oldCardKey: JSON.parse(localStorage.getItem('userInfo'))?.oldCardKey || '',  // 写入扇区控制的key
        sector: '',  // 扇区
    },
    mutations: {
        setToken (state,paylaod){    // 存入token
            state.token = paylaod
        },
        delToken (state, paylaod) {  // 清空token
            state.token =  paylaod
        },
        setUser (state,paylaod){    // 存入账号信息 和集团账号
            state.user = paylaod.user
            paylaod.manager ? state.manager =  paylaod.manager : ''  // 有传就存入, 没有就不管
        },
        delUser (state, paylaod) {  // 
            state.user =  paylaod
        },
        setType(state, payload) {   // 存入账号类型 
            state.type = payload
            
        },
        delType (state, paylaod) {  // 
            state.type =  paylaod
        },
        setCard(state, paylaod) {   // 写卡的key
            state.cardKey =  paylaod.cardKey
            state.sector =  paylaod.sector
            if(!state.oldCardKey) {
                state.oldCardKey = paylaod  // 旧的key
            }
        },
        setOldCard(state, paylaod) {   // 写卡的key
                state.oldCardKey = paylaod  // 存入旧的key
        },
        delCard (state, paylaod) {  //  删除新key
            state.cardKey =  paylaod
        },
    },
    actions: {
    },
   
}