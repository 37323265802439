export default {
    namespaced: true,
    state: {
        local: '',
    },
    mutations: {  // 本地语言
        setlocal(state, data) {
            state.local = data
        }
    }
}