<template>
   <!-- <div> -->
      <el-button class="bgc" :loading="loading" :disabled="disabled"> {{ text }}
         <slot name="icon"></slot>
      </el-button>
   <!-- </div> -->
</template>
<script>
export default {
   name: 'MyButton',
   mixins: [],
   props: { 
    text: String,
    loading: {
      default: false,
      type: Boolean
    },
    disabled: {
      default: false,
      type: Boolean
    }
   },
   methods: {
     
   }
};
</script>

   <style lang='scss' scoped>
     .bgc {
        background-color: #FD8137;
        color: #fff;
        height: 40px;
        line-height: 15px;
     }
     ::v-deep .el-button.is-disabled, .el-button.is-disabled:focus, .el-button.is-disabled:hover {
        background-color: #e8e8e8;
     }
  </style>