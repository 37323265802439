import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './locales'  // 国际化




// 按需引入elemen组件
import './utils/element'

// 本地字体图标库
import './icons/iconfont.css'

// 全局组件
import MyTable from './components/MyTable.vue'
import MyCard from './components/MyCard.vue'
import MyPagination from './components/MyPagination.vue'
import MyButton from './components/MyButton.vue'
import MyDialog from  './components/MyDialog.vue'

Vue.component('MyDialog', MyDialog)
Vue.component('MyButton', MyButton)
Vue.component('MyTable', MyTable)
Vue.component('MyCard',MyCard)
Vue.component('MyPagination',MyPagination)

Vue.config.productionTip = false
// icon图标


 new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

