import Vue from 'vue';
import { Button, Card, Form, Input, FormItem,  Container,Header, Aside, Main,
    Menu,Submenu,MenuItem,  MenuItemGroup, Row, Col,  Tabs, TabPane, Tag, Icon, 
    Table, Select,Option,Dialog,Switch,Checkbox,CheckboxGroup, DatePicker,
    TableColumn, Pagination ,Message, Popover,MessageBox,  Dropdown,Loading ,TimePicker,
    DropdownMenu,TimeSelect, Radio,  Tree,Progress,
    RadioGroup,
    DropdownItem,
} from 'element-ui';

Vue.use(Switch)
Vue.use(Button)
Vue.use(Card)
Vue.use(Input)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Container)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Row)
Vue.use(Col)
Vue.use( Tabs)
Vue.use(TabPane)
Vue.use(Tag)
Vue.use(Icon)
Vue.use( Table)
Vue.use(TableColumn)
Vue.use(Pagination)
Vue.use(Select)
Vue.use(Option)
Vue.use(Dialog)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(DatePicker)
Vue.use(Popover)
Vue.use( Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(TimePicker)
Vue.use(TimeSelect)
Vue.use( Radio)
Vue.use( Tree)
Vue.use( RadioGroup)
Vue.use(Progress)
Vue.use(Loading.directive)

Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;
