<template>
   <div>
    <el-table
            :data="Data"
            ref="singleTable"
            :header-cell-style="{ backgroundColor: '#f1f1f1' }"
            :cell-style="{'text-agign': 'center'}"
            :highlight-current-row="colorBlock"
            @current-change="handleCurrentChange"
            border
            v-loading="loading"
            :element-loading-text="loadingText"
            style="width: 100%">
            <!-- 通用序号列表, 用serialNumber控制是否生成 -->
            <el-table-column
            v-if="serialNumber"
            align="center"
            :label="$t('序号')"
            width="150"
            >
            <template #default="{$index}">
               {{  $index + 1 }}
            </template>
         </el-table-column>
         <!-- 自定义前面内容插槽 -->
         <slot name="custom"></slot>
            <!-- 自定义数组循环生产table -->
            <el-table-column
               v-for="(item, index) in tableList"
               :key="index"
               align="center"
               :width="item.width"
               :prop="item.prop"
               :label="item.name"
               min-width="80">
            </el-table-column>
            <!-- 插槽自定义尾部或者全部内容 -->
            <slot name="tail"></slot>
            <!-- 自定义操作按钮, 通过operation控制生成, 使用插槽operation进行自定义 -->
            <el-table-column :label="$t('操作')"  align="center" v-if="operation"  :min-width="operationW">
                 <template #default="scoped">
                    <slot name="operation" :scoped="scoped"></slot>
                 </template>
            </el-table-column>
         </el-table>  
   </div>
</template>
<script>
export default {
   name: 'MyTable',
   props: {
    // 控制操作列
     operation: {
        default: true,
        type: Boolean
     },
    //  控制序号列
     serialNumber: {
        default: true,
        type: Boolean
     },
     colorBlock: { // 色块
        default: false,
        type: Boolean
     },
    //  请求的数据
     Data: Array,
     // 根据接口手动生成的内容
     tableList: Array,
     keys: {
        default: '1',
        type: [String, Number]
     },
     operationW: {
       default: '120%',
       type: String,
     }
   },
   data() {
      return {
         loading: true,
         loadingText: '',
      }
   },
   watch: { 
      Data() {
         // setTimeout(() => {      // 两秒后自动关闭
         //    this.loading = false
         // }, 2000)
        
            this.loading = false
        
      }
   },
   created() {
      setTimeout(() => {      // 5秒后自动关闭
            this.loading = false
         }, 5000)
   },
   methods: {
      handleCurrentChange(currentRow) {
          if(this.colorBlock) {
            //  console.log(currentRow);
             this.$emit('selected',currentRow )
          }
      },
      setCurrentRow(row) {
         console.log(22323, row);
         this.$refs.singleTable.setCurrentRow(row)
      }
   }
};
</script>
<style lang='scss' scoped>
 ::v-deep .el-loading-spinner .path  {
   stroke: #fb7631
 }

 ::v-deep .el-loading-spinner .el-loading-text {
	color: #fb7631;
}
</style>