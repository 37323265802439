<template>
   <div>
      <el-dialog
         :visible="show"
          modal-append-to-body
         :show-close="false"
         :close-on-click-modal="false"
         :width="dialogWidth"
         :before-close="handleClose">
       <template #title>
         <!-- 通用头部 -->
         <slot name="title">
             <div class="title">
                <span>{{ title }}</span>
                <el-icon class="el-icon-circle-close close" @click.native="$emit('update:show', false)"></el-icon>
             </div>
             <div class="dashed"></div>
         </slot>
       </template>
       <template>
         <!-- <div>猪蹄内容</div> -->
          <slot name="main"></slot>
       </template>

       <template #footer>
         <!-- <div>自定义底部</div> -->
          <slot name="footer"></slot>
       </template>
      </el-dialog>
   </div>
</template>

<script>
export default {
   name: 'MyDialog',
   props: {
      show: {
         type: Boolean,
         required: true,
      },
      title: {
         type: String,
         required: true
      },
      dialogWidth: {
         default: '30%',
         type: String,
      }
   },
   data() {
     return {
      
     }
   },
   computed: {
     
   },
   watch: {
     
   },
   mounted() {
     
   },
   methods: {
      handleClose() {
         this.$emit('update:show', false)
      }
   }
};
</script>
<style lang='scss' scoped>
.title {
   display: flex;
   justify-content: space-between;
   span {
      font-size: 14px;
      font-weight: 550;
   }
}
.close {
   cursor: pointer;
}
.dashed {
   margin-top: 15px;
   border-bottom: 1px dashed #BBBBBB ;
}
</style>