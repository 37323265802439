import VueI18n from 'vue-i18n'
import Vue from 'vue'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import ElementLocale from 'element-ui/lib/locale'

import store from '@/store'
// 国际化 
import {enFunction} from './en'
import {zhFunction} from './zh'

let en = enFunction()
let zh = zhFunction()

Vue.use(VueI18n)
const messages = {
  en: {
    ...en,
    ...enLocale
  },
  zh: {
    ...zh,
    ...zhLocale
  }
}

const locale = store.state.locales.local  // 获取本机默认配置语言
console.log(locale);

const i18n = new VueI18n({
  locale: locale, // 默认语言
  messages
})

// 非 vue 文件中使用这个方法
export const translate = (localeKey) => {
  
      const hasKey = i18n.te(localeKey, i18n.locale)  // 使用i18n的 te 方法来检查是否能够匹配到对应键值
      const translatedStr = i18n.t(localeKey)
      if (hasKey) {
          return translatedStr
      }
      return localeKey
}

ElementLocale.i18n((key, value) => i18n.t(key, value))


export default i18n