<template>
   <div style="margin-top: 0.1vw; text-align: right;">
                  <el-pagination
                  @size-change="changeSizes"
                  @current-change="changeCurrent"
                  @prev-click="prev"
                  @next-click="next"
                  @keyup.enter="submit"
                  background
                  style="background-color: #FD8137s;"
                  :current-page="current"
                  :page-size="pageSize"
                  :page-sizes="[5, 10, 20]"
                  layout="prev, pager, next, jumper, slot, total, sizes"
                  :total="total">
                  <template>
                     <span style="margin: 0 10px;">
                        <el-button  style="border: 1px solid #000;" plain @click="submit">{{ $t('确定') }}</el-button>
                     </span>
                  </template>
               </el-pagination>
               </div>
</template>
<script>
export default {
   name: 'MyPagination',
   props: {
      total: {
      required: true
     },
     current: {
      required: true
     },
     pageSize: {
      required: true
     }
   },
   methods: {
      // 切换最大页数
      changeSizes(e) {
          this.$emit('changeSize', e)
      },
      // 切换当前页
      changeCurrent(e) {
         this.$emit('changeCurrent', e)
      },
      // 上一页
      prev(e) {
         this.$emit('prev', e)
      },
      // 下一页
      next(e) {
         this.$emit('next', e)
      },
      // 确定按钮
      submit(e) {
         this.$emit('submit', e)
      },
   }
};
</script>
<style lang='scss' scoped>
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
   background-color: #fb7136;
}
::v-deep .el-button.is-plain:focus, .el-button.is-plain:hover {
   color: #fb7136;
}
::v-deep .el-button.is-active, .el-button.is-plain:active {
   color: #fb7136;
}
</style>