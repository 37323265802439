import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';  //本地持久化插件
// tab栏模块
import tabs from './modules/tabs'
// 用户信息
import userInfo  from './modules/userInfo'
// 本地语言
import locales from './modules/locales';

const tab = createPersistedState({
  key: 'tab',
  paths: ['tabs']
})

const userInfos = createPersistedState({
  key: 'userInfo',
  paths: ['userInfo']
})

const locale = createPersistedState({
  key: 'locales',
  paths: ['locales']
})

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    tabs ,
    userInfo,
    locales
  },
  plugins: [     
    tab, userInfos,locale
]
})
